

import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import type DeepCompsetAnalysisService from '@/modules/deep-analysis/deep-analysis.service';
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import type ProvidersService from '@/modules/providers/providers.service';

const AVAILABLE_PROVIDERS = ['all', 'booking', 'expedia'];

@Component({
    components: { CustomSelect },
})
export default class ProviderFilter extends Vue {
    @inject(KEY.DeepCompsetAnalysisService) deepCompsetAnalysisService!: DeepCompsetAnalysisService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;

    get value() {
        return this.deepCompsetAnalysisService.provider;
    }

    set value(value: string) {
        this.deepCompsetAnalysisService.provider = value;
    }

    get items(): Item[] {
        return AVAILABLE_PROVIDERS.map((value): Item => ({
            value,
            name: this.getProviderLabel(value),
            disabled: false,
        }));
    }

    getProviderLabel(providerName: string) {
        return this.providersService.allProviders[providerName]?.label;
    }
}
