
import type UserService from '@/modules/user/user.service';
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import type HotelsService from '@/modules/hotels/hotels.service';
import UserViewService, { UserViewServiceS } from '@/modules/user/user-view.service';
// import CurrencySwitcher from '@/modules/common/components/currency-switcher.vue';

@Component({
    components: {
        DateDocumentFilter,
        // CurrencySwitcher,
    },
})
export default class DeepAnalysisHeader extends Vue {
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;
    @inject(UserViewServiceS) private userViewService!: UserViewService;

    infoTooltip: boolean = false;

    get isChainOrCluser() {
        return this.userService.isChainOrClusterUser;
    }

    get hotelName(): string | null {
        const { currentHotelId } = this.userService;

        if (!currentHotelId) {
            return null;
        }

        return this.hotelsService.hotelNames[currentHotelId];
    }

    toCluster() {
        this.userViewService.goToClusterPage();
    }
}
